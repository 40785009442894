<template>
  <div>
    <!-- Media dialog -->
    <v-overlay
      v-model="state.mediaDialog.value"
      class="vsw-dialogs--media-dialog"
      opacity="0.8"
      :z-index="999999"
    >
      <div class="vsw-dialogs--media-dialog__wrap">
        <v-img
          v-if="state.mediaDialog.type === 'image'"
          class="vsw-dialogs--media-dialog__img"
          :class="{'ma-4': state.mediaDialog.objectFit === 'contain'}"
          :src="state.mediaDialog.isDirectLink
            ? state.mediaDialog.items[state.mediaDialog.selectedIndex]
            : getApiAssetUrl(state.mediaDialog.items[state.mediaDialog.selectedIndex])
          "
          width="80vw"
          :contain="state.mediaDialog.objectFit === 'contain'"
        />
        <iframe
          v-if="state.mediaDialog.type === 'youtube'"
          :src="state.mediaDialog.items[state.mediaDialog.selectedIndex]"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
          class="vsw-dialogs--media-dialog__youtube-video"
        ></iframe>
        <div class="vsw-dialogs--media-dialog__description">
          {{mediaDialogDescription}}
        </div>
        <div
          class="vsw-dialogs--media-dialog__icon--close"
          @click="state.mediaDialog.value = false"
        >
          <v-icon size="32px">mdi-close</v-icon>
        </div>
        <div
          class="vsw-dialogs--media-dialog__icon--left"
          v-if="state.mediaDialog.items.length > 1"
          @click="loadPreviousImage()"
        >
          <v-icon size="48px">mdi-chevron-left</v-icon>
        </div>
        <div
          class="vsw-dialogs--media-dialog__icon--right"
          v-if="state.mediaDialog.items.length > 1"
          @click="loadNextImage()"
        >
          <v-icon size="48px">mdi-chevron-right</v-icon>
        </div>
      </div>
    </v-overlay>

    <!-- Ship tour dialog -->
    <v-overlay
      v-if="state.shipTourDialog.value"
      v-model="state.shipTourDialog.value"
      class="vsw-dialogs--ship-tour-dialog"
      opacity="0.8"
      :z-index="999999"
    >
      <div class="vsw-dialogs--ship-tour-dialog__wrap">
        <iframe
          :src="state.shipTourDialog.data.link"
          width="100%"
          height="100%"
          frameborder="0"
        ></iframe>
        <div
          class="vsw-dialogs--ship-tour-dialog__icon--close"
          @click="state.shipTourDialog.value = false"
        >
          <v-icon size="32px">mdi-close</v-icon>
        </div>
      </div>
    </v-overlay>

    <!-- Cruise program details dialog -->
    <v-dialog
      class="vsw-dialogs--cruise-program-dialog"
      v-if="state.cruiseProgramDetailsDialog.value"
      v-model="state.cruiseProgramDetailsDialog.value"
      scrollable
      :z-index="999999"
    >
      <v-card>
        <v-card-title>
          Программа дня
          <v-spacer></v-spacer>
          <v-btn
            @click="state.cruiseProgramDetailsDialog.value = false"
            icon
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text
         style="height: 600px; padding: 8px 24px; position: relative"
        >
          <loader
            :state="state"
            :show="state.cruiseProgramDetailsDialog.loader"
            :with-backgound="true"
            :is-absolute="true"
          />
          <div
            v-if="!state.cruiseProgramDetailsDialog.loader"
            class="vsw-dialogs--cruise-program-dialog__content"
          >
            <div class="vsw-dialogs--cruise-program-dialog__content__text-content">
              <div class="vsw-dialogs--cruise-program-dialog__content__text-content__title">
                {{state.cruiseProgramDetailsDialog.data.title}}
              </div>
              <div class="vsw-dialogs--cruise-program-dialog__content__text-content__sub-title mt-2">
                <div>
                  {{state.cruiseProgramDetailsDialog.data.city}}
                </div>
                <div class="sub-title--count">
                  <img
                    :src="getApiAssetUrl(state.cruiseProgramDetailsDialog.data.visitors[0].icon)"
                    width="18px"
                    height="18px"
                    class="mr-2"
                  >
                  {{state.cruiseProgramDetailsDialog.data.visitors[0].text}}
                </div>
              </div>
              <v-divider class="my-3"></v-divider>
              <div class="vsw-dialogs--cruise-program-dialog__content__text-content__description">
                <div class="vsw-dialogs--cruise-program-dialog__features__icons">
                  <div
                    class="vsw-dialogs--cruise-program-dialog__features__icons__icon"
                    v-for="(item, index) in state.cruiseProgramDetailsDialog.data.featuresImages"
                    :key="'program-feature-icons' + index"
                  >
                    <new-tooltip :text="item.text">
                      <template>
                        <div>
                          <img :src="getApiAssetUrl(item.icon)">
                        </div>
                      </template>
                    </new-tooltip>
                  </div>
                </div>
                {{state.cruiseProgramDetailsDialog.data.description}}
              </div>
            </div>
            <div class="vsw-dialogs--cruise-program-dialog__content__images__wrap">
              <v-img
                :src="getApiAssetUrl(
                  state.cruiseProgramDetailsDialog.data.images[state.cruiseProgramDetailsDialog.data.selectedImageIndex]
                )"
                width="100%"
                height="100%"
              />
              <v-btn class="mr-0" @click="setPreviousCruiseProgramImg()" depressed>
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn class="mr-2" @click="setNextCruiseProgramImg()" depressed>
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
              {{state.cruiseProgramDetailsDialog.data.selectedImageIndex + 1}} / {{state.cruiseProgramDetailsDialog.data.images.length}}
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Email dialog -->
    <v-dialog
      v-if="state.emailDialog.value"
      v-model="state.emailDialog.value"
      scrollable
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          Отправить заявку на круиз
          <v-spacer></v-spacer>
          <v-btn
            @click="state.emailDialog.value = false"
            icon
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text style="height: 600px; padding: 8px 24px">
          <div class="vsw-email-dialog__description">
            <div class="vsw-email-dialog__description__label">
              <span>Маршрут:</span>
              {{state.emailDialog.data.route.value}}
            </div>
            <div class="vsw-email-dialog__description__label">
              <span>Название теплохода:</span>
              {{state.emailDialog.data.motor_ship.value}}
            </div>
            <div class="vsw-email-dialog__description__label">
              <span>Дата:</span>
              {{state.emailDialog.data.date.value}}
            </div>
          </div>
          <v-divider class="my-4"></v-divider>
          <v-form v-model="state.emailFormIsValid" ref="emailForm">
            <input
              type="text"
              id="special_email_text_input"
              name="special_text_input"
              style="display:none !important"
              tabindex="-1"
              autocomplete="off"
            >
            <input
              id="special_email_checkbox"
              type="checkbox"
              name="special_checkbox"
              style="display: none !important"
              tabindex="-1"
              autocomplete="off"
            >
            <v-text-field
              v-model="state.emailDialog.data.userFullName.value"
              :label="state.emailDialog.data.userFullName.label"
              :color="state.componentProps.accentBgColor1"
              :rules="isUserFullNameValid"
              filled
              :required="state.emailDialog.data.userFullName.isRequired"
            ></v-text-field>
            <v-text-field
              v-model="state.emailDialog.data.userPhoneNumber.value"
              v-mask="`+${state.emailDialog.data.userPhoneNumber.code} (###) ### ## ##`"
              @blur="handleInputBlurUserPhoneNumber()"
              :rules="isUserPhoneNumberValid"
              :label="state.emailDialog.data.userPhoneNumber.label"
              :color="state.componentProps.accentBgColor1"
              filled
              :required="state.emailDialog.data.userPhoneNumber.isRequired"
            ></v-text-field>
            <v-text-field
              v-model="state.emailDialog.data.userEmail.value"
              :label="state.emailDialog.data.userEmail.label"
              :color="state.componentProps.accentBgColor1"
              :rules="isUserEmailValid"
              filled
              :required="state.emailDialog.data.userEmail.isRequired"
            ></v-text-field>
            <v-textarea
              v-model="state.emailDialog.data.comment.value"
              :label="state.emailDialog.data.comment.label"
              :color="state.componentProps.accentBgColor1"
              filled
              :required="state.emailDialog.data.comment.isRequired"
            ></v-textarea>
            <div class="vsw-dialogs__checkbox-container">
              <v-checkbox
                class="mt-0"
                v-model="state.emailDialog.data.agreement.value"
                color="#1976d2"
                :required="true"
                :rules="isUserAgreementValid"
              >
              </v-checkbox>
              <span>
                Нажимая на "Отправить" вы соглашаетесь на обработку ваших
                <a target="_blank" href="https://vodohod.com/privacy/">
                  персональных данных
                </a>
              </span>
            </div>
          </v-form>
        </v-card-text>
        <v-card-actions class="ma-0 pa-0">
          <button
            class="
              vsw-filter__item
              vsw-filter__search-btn
              vsw-widget__btn-1 vsw-widget__btn-1--fill
            "
            @click="handleBtnSendEmail()"
            :is-disabled="!state.emailFormIsValid"
          >
            Отправить заявку
          </button>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mask } from 'vue-the-mask'
import newTooltip from "@/components/common/new-tooltip";

export default {
  components: { newTooltip },
  props: {
    state: Object,
    getApiAssetUrl: Function,
    openImageViewerDialog: Function,
    updateApiData: Function,
    getApiUrl: Function,
  },
  directives: { mask },
  data () {
    return {
      agreement: false
    }
  },
  watch: {
    'state.emailDialog.value' (newValue) {
      if (!newValue) {
        this.resetEmailDialog()
      }
    }
  },
  computed: {
    isUserFullNameValid () {
      return [
        v => !!v || 'Обязательное поле'
      ]
    },
    isUserPhoneNumberValid () {
      return [
        v => v.length === 18 || 'Укажите корректный номер телефона'
      ]
    },
    isUserEmailValid () {
      const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return [
        v => v.length === 0 || emailRegex.test(v) || 'Укажите корректный e-mail адрес'
      ]
    },
    isUserAgreementValid () {
      return [
        v => !!v
      ]
    },
    mediaDialogDescription () {
      return `${this.state.mediaDialog.selectedIndex + 1}/${this.state.mediaDialog.items.length}`
    }
  },
  methods: {
    loadPreviousImage () {
      const selectedIndex = this.state.mediaDialog.selectedIndex
      const selectedIndexClamped = Math.max(0, selectedIndex - 1)
      this.state.mediaDialog.selectedIndex = selectedIndexClamped
    },
    loadNextImage () {
      const selectedIndex = this.state.mediaDialog.selectedIndex
      const selectedIndexClamped = Math.min(selectedIndex + 1, this.state.mediaDialog.items.length - 1)
      this.state.mediaDialog.selectedIndex = selectedIndexClamped
    },
    setPreviousCruiseProgramImg () {
      const selectedIndex = this.state.cruiseProgramDetailsDialog.data.selectedImageIndex
      const selectedIndexClamped = Math.max(0, selectedIndex - 1)
      this.state.cruiseProgramDetailsDialog.data.selectedImageIndex = selectedIndexClamped
    },
    setNextCruiseProgramImg () {
      const selectedIndex = this.state.cruiseProgramDetailsDialog.data.selectedImageIndex
      const selectedIndexClamped = Math.min(selectedIndex + 1, this.state.cruiseProgramDetailsDialog.data.images.length - 1)
      this.state.cruiseProgramDetailsDialog.data.selectedImageIndex = selectedIndexClamped
    },
    resetEmailDialog () {
      this.state.emailDialog.data.userFullName.value = ''
      this.state.emailDialog.data.userPhoneNumber.value = ''
      this.state.emailDialog.data.userEmail.value = ''
      this.state.emailDialog.data.comment.value = ''
      this.state.emailDialog.data.agreement.value = false
    },
    handleBtnSendEmail () {
      const params = this.getEmailDataToSend()
      this.$refs.emailForm.validate()
      this.$nextTick(() => {
        if (this.isAllowedToSendEmail() && this.state.emailFormIsValid) {
          this.state.notifications.emailSent.options.icon = 'mdi-email-multiple-outline'
          this.state.notifications.emailSent.options.text = 'Отправляем Email сообщение...'
          this.state.notifications.emailSent.value = true
          this.updateApiData({
            url: this.getApiUrl('mail'),
            params,
            options: {
              setApiData: false,
              setApiShipData: false
            }
          })
            .then((result) => {
              this.state.notifications.emailSent.value = false
              this.state.notifications.emailSent.options.icon = 'mdi-checkbox-marked-circle-outline'
              this.state.notifications.emailSent.options.text = 'Email сообщение успешно отправлено'
              this.state.notifications.emailSent.value = true
            })
            .catch((error) => {
              console.log(error)
              this.state.notifications.emailSent.value = false
              this.state.notifications.emailSent.options.icon = 'mdi-close-octagon-outline'
              this.state.notifications.emailSent.options.text = 'Ошибка при отправке Email. Пожалуйста свяжитесь с нами по телефону'
              this.state.notifications.emailSent.value = true
            })
          this.state.emailDialog.value = false
        }
      })
    },
    isHoneypotTriggered () {
      const honeypotTextInputNode = document.querySelector('#special_email_text_input').value
      const honeypotCheckboxNode = document.querySelector('#special_email_checkbox')
      const isCheckoxTriggered = honeypotCheckboxNode.checked
      const isTextInputTriggered = honeypotTextInputNode.length > 0
      return isCheckoxTriggered || isTextInputTriggered
    },
    isAllowedToSendEmail () {
      // Spam check #1
      const timePassedSinceMounted = new Date().getTime() - this.state.timeAppMounted
      const timeCheckPassed = timePassedSinceMounted > this.state.waitPeriodBeforeCanSendEmail
      return timeCheckPassed && !this.isHoneypotTriggered()
    },
    getEmailDataToSend () {
      const params = {
        email: this.state.emailDialog.data.userEmail.value,
        phone: this.state.emailDialog.data.userPhoneNumber.value,
        name: this.state.emailDialog.data.userFullName.value,
        route: this.state.emailDialog.data.route.value,
        cruise: `${this.state.selectedItem.NAME}`,
        motor_ship: `${this.state.selectedItem.MOTOR_SHIP.NAME} (code: ${this.state.selectedItem.MOTOR_SHIP.CODE})`,
        date: `${this.state.selectedItem.DATE_START} - ${this.state.selectedItem.DATE_END} (${this.state.selectedItem.DAYS_COUNT})`,
        comment: this.state.emailDialog.data.comment.value,
        isHoneypotTriggered: this.isHoneypotTriggered()
      }
      return params
    },
    handleInputBlurUserPhoneNumber () {
      if (this.state.emailDialog.data.userPhoneNumber.value === '') {
        this.state.emailDialog.data.userPhoneNumber.value = `+${this.state.emailDialog.data.userPhoneNumber.code}`
      }
    }
  },
  mounted () {
    window.addEventListener('keyup', (event) => {
      if (this.state.mediaDialog.value) {
        if (event.keyCode === 37) {
          this.loadPreviousImage()
        } else if (event.keyCode === 39) {
          this.loadNextImage()
        }
      }
    });
  }
}
</script>

<style lang="scss">
.vsw-dialogs--media-dialog.v-overlay--active,
.vsw-dialogs--ship-tour-dialog.v-overlay--active {
  backdrop-filter: blur(36px);
}
.vsw-dialogs {
  &--cruise-program-dialog {
    width: 80vw;
    .SM & {
      width: 90vw;
    }
    &__features  {
      &__icons {
        display: flex;
        align-items: center;
        margin: 8px 0;
        &__icon {
          margin-right: 16px;
          width: 20px;
          height: 20px;
          img {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
    &__content {
      display: flex;
      flex-direction: column;
      gap: 32px;
      grid-template-columns: 40% calc(60% - 32px);

      @media (min-width: 768px) {
        display: grid;
      }
      .MD & {
        display: block;
      }
      &__text-content {
        &__sub-title {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 16px;
          padding-bottom: 8px;
          gap: 24px;

          .sub-title--count {
            display: flex;
            align-items: center;
            gap: 8px
          }
        }
        &__title {
          font-size: 20px;
          font-weight: 600;
          display: inline;
          color: var(--vsw-accent-color-1);
        }
        &__description {
          margin: 12px 0;
        }
      }
      &__images {
        &__wrap {
          width: auto;
          height: 100%;
          min-height: unset;

          @media (min-width: 768px) {

          }
        }
      }
    }
  }
  &--media-dialog, &--ship-tour-dialog {
    &__wrap {
      background-color: #fff;
      height: 100%;
      width: 100%;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &__img {
      width: 50vw;
      height: 80vh;
    }
    &__youtube-video {
      width: 80vw;
      height: 80vh;
    }
    &__description {
      position: fixed;
      bottom: 32px;
    }
      z-index: 10;
    &__icon {
      &--close {
        cursor: pointer;
        position: fixed;
        top: 16px;
        right: 16px;
      }
      &--left {
        cursor: pointer;
        position: fixed;
        top: 50%;
        left: 16px;
      }
      &--right {
        cursor: pointer;
        position: fixed;
        top: 50%;
        right: 16px;
      }
    }
  }
  &--ship-tour-dialog {
    &__wrap {
      height: 86vh;
      width: 90vw;
    }
  }
  &__checkbox-container {
    display: flex;
    gap: 12px;
    align-items: flex-start;
    .v-input--selection-controls {
      margin: 0;
      padding: 0;
      position: relative;
      top: -2px;
    }
  }
}
</style>
